// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAppVGlGbODA62eaBR6GZpU_Rpzh9Gu5Bk",
  authDomain: "ecommercedatabase-cd029.firebaseapp.com",
  projectId: "ecommercedatabase-cd029",
  storageBucket: "ecommercedatabase-cd029.appspot.com",
  messagingSenderId: "970741277689",
  appId: "1:970741277689:web:0c763fe5b331e1be715328"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);

const auth = getAuth(app);

export default auth;